<!--
 * @Description: 欠费订单 lackOrder
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-19 15:56:06
 * @LastEditors: LinFeng
-->
<template>
  <div class='body'>
    <van-nav-bar title="欠费订单"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="list"
               v-for="(item,index) in lackOrderList"
               :key=index>
        <van-col style="width:100%">
          <van-row class="lineOne">
            <van-col class="lineOnePlate"
                     style="margin-left:20px">
              {{item.plateNumber}}
            </van-col>
            <van-col class="lineOnePlateColor">
              <van-tag type="primary">{{item.plateColor}}</van-tag>
            </van-col>
            <van-col class="lineOneMoney">
              {{item.lackOrderMoney}}元
            </van-col>
          </van-row>
          <van-row class="lineTwo"></van-row>
          <van-row class="lineThree">
            <van-col :span=12
                     class="lineThreeLeft">
              <van-row class="lineThreeLeft-lineOne">开始时间</van-row>
              <van-row class="lineThreeLeft-lineTwo">{{item.entranceTime}}</van-row>
              <van-row class="lineThreeLeft-lineThree">停车时长</van-row>
              <van-row class="lineThreeLeft-lineFour">{{item.parkDuration}}</van-row>
            </van-col>
            <van-col :span=12
                     class="lineThreeRight">
              <van-row class="lineThreeRight-lineOne">结束时间</van-row>
              <van-row class="lineThreeRight-lineTwo">{{item.leaveTime}}</van-row>
              <van-row class="lineThreeRight-lineThree"
                       @click="handleClickDetail(item)">
                订单详情
                <img src="@/assets/homePage/rig.png"
                     alt="">
              </van-row>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import duration from '@/utils/duration'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      money: '0.00', // 开票金额
      selectAll: '', // 全选或全不选
      result: [], // 多选绑定数组
      lackOrderList: [], // 可开票订单获取数组
      valOrder: [], // 订单数组
      paymentSequenceList: [], // 选择订单流水号
      listLength: 0, // 数据总数
      title: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getLackOrder()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取欠费订单
    getLackOrder () {
      const info = {
        plateNumber: this.$route.query.plateNumber
      }
      this.$lackOrder.queryArrangeOrder(info).then(res => {
        this.lackOrderLength = res.resultEntity.length
        res.resultEntity.forEach(item => {
          item.lackOrderMoney = Number(Number(item.receivableMoneyAmount - item.receivedMoneyAmount) / 100).toFixed(2)
          item.parkDuration = duration(item.parkDuration)
          item.receivableMoneyAmount = Number(Number(item.receivableMoneyAmount) / 100).toFixed(2)
        })
        this.lackOrderList = res.resultEntity
        // this.lackOrderList.parkDuration = duration(this.lackOrderList.parkDuration)
        console.log(this.lackOrderList, 'this.lackOrderList')
        if (this.lackOrderList.length === 0) {
          this.$router.go(-1)
        }
      })
    },
    // 订单详情点击事件
    handleClickDetail (item) {
      this.$router.push({ name: 'lackOrderDetail', query: { lackOrderItem: item } })
    },
    // 顶部返回
    handleClickTopBack () {
      // this.$router.go(-1)
      this.$router.push('/parForParking')
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    width: 100%;
    // height: calc(100% - 70px - 46px - 10px);
    height: calc(100% - 70px - 10px);
    overflow-x: hidden;
    .list {
      width: 93.6%;
      height: 150px;
      margin-left: 3.2%;
      background-color: #fff;
      margin-top: 10px;
      .lineOne {
        width: 100%;
        height: 45.5px;
        line-height: 45.5px;
        position: relative;
        .lineOnePlate {
          font-size: 17px;
          color: #333333;
        }
        .lineOnePlateColor {
          margin-left: 10px;
          line-height: 42.5px;
        }
        .lineOneMoney {
          float: right;
          position: absolute;
          right: -190px;
          font-size: 17px;
          color: #f56060;
        }
      }
      .lineTwo {
        width: 100%;
        height: 1px;
        background: #e7e8e8;
      }
      .lineThree {
        padding-left: 20px;
        .lineThreeLeft {
          width: 50%;
          .lineThreeLeft-lineOne {
            color: #666666;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeLeft-lineTwo {
            color: #333333;
            font-size: 15px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeLeft-lineThree {
            color: #666666;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeLeft-lineFour {
            color: #333333;
            font-size: 15px;
            height: 25px;
            line-height: 25px;
          }
        }
        .lineThreeRight {
          padding-left: 10px;
          width: 50%;
          .lineThreeRight-lineOne {
            color: #666666;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeRight-lineTwo {
            color: #333333;
            font-size: 15px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeRight-lineThree {
            color: #333333;
            font-size: 15px;
            height: 50px;
            line-height: 50px;
          }
        }
      }
    }
  }
}
</style>
