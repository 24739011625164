/*
 * @Description:
 * @Author: LinFeng
 * @Github: https://github.com/LinFeng-tt
 * @Date: 2021-07-08 19:17:13
 * @LastEditors: LinFeng
 */
export default function formatDuration (duration) {
  let parkDuration
  if (duration === undefined) {
    parkDuration = 0 + '分钟'
  } else if (duration >= 0 && duration < 60) {
    parkDuration = duration + '分钟'
  } else if (duration >= 60 && duration < 1440) {
    parkDuration = parseInt(duration / 60) + '小时' + duration % 60 + '分钟'
  } else if (duration >= 1440) {
    parkDuration = parseInt(duration / 1440) + '天' + parseInt((duration % 1440) / 60) + '小时' + (duration % 1440) % 60 + '分钟'
  }
  return parkDuration
}
